<template>
  <div>
    <el-row>
      <el-col>
        <el-form :inline='true'>
<!--          <el-form-item label="省份:">-->
<!--            <el-select v-model="filters.provinceCode" filterable clearable>-->
<!--              <el-option v-for="item in provinceSearchOptions" :key="item.id" :label="item.name"-->
<!--                         :value="item.code"></el-option>-->
<!--            </el-select>-->
<!--          </el-form-item>-->
          <el-form-item label="履约日期:">
            <el-date-picker @change="changePicker" v-model="pickerData" type="daterange" range-separator="至"
                            start-placeholder="开始日期" value-format="yyyy-MM-dd HH:mm:ss" end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="图审人员名称:">
<!--            <el-autocomplete value-key="name" v-model="filters.name"-->
<!--                             @input="(e) => (filters.name = utils.validForbid(e))" placeholder="请输入图审人员名称"-->
<!--                             @select="handleSelect"-->
<!--                             :fetch-suggestions="fetchSuggestions"></el-autocomplete>-->
            <el-select v-model="filters.name" clearable filterable>
              <el-option v-for="item in this.tableData" :key="item.id" :label="item.value"
                         :value="item.userName" ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="专家类型:">
            <el-select v-model="filters.expertType" clearable>
              <el-option v-for="item in expertOptions" :key="item.id" :label="item.value"
                         :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <!-- 列表 -->
    <el-table id="exportTab" :data="tableData" border style="width: 100%" v-loading="listLoading"
              ref="multipleTable" :highlight-current-row='true' >
      <el-table-column type='index' label="序号" width='50' align="center"></el-table-column>
      <el-table-column prop="userName" align="center" label="姓名">
        <template slot-scope="scope">
          <div style="text-align: center;">
            <!--显示提示文字 el-tooltip-->
<!--            <el-tooltip :content="scope.row.userName" placement="left">-->
              <el-button type="text" @click="getProject(scope.row)">
                <span style="text-align: center;" v-html="formatUserName(scope.row.userName)"></span>
              </el-button>
<!--            </el-tooltip>-->
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="projectAreaNums" label="参加区(县)" align="center" width="60"></el-table-column>
      <el-table-column  label="项目总面积(平方米)" align="center"  >
        <el-table-column prop="userSubmitOpinionNum" label="普通" align="center" class-name="performance-column">
          <template slot-scope="scope">
            {{totalProjectAreaListFormal(scope.row)}}
          </template>
        </el-table-column>
        <el-table-column prop="userSubmitOpinionNum" class-name="performance-column"  label="复核" align="center">
          <template slot-scope="scope">
            {{totalProjectAreaListReview(scope.row)}}
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column  label="绩效面积(平方米)" align="center">
        <el-table-column prop="preArea" label="普通" align="center">
          <template slot-scope="scope">
            {{scope.row.expertType===162?'-': totalProjectPayAreaListFormal(scope.row)}}
          </template>
        </el-table-column>
        <el-table-column prop="preAreaR" label="复核" align="center">
          <template slot-scope="scope">
            {{scope.row.expertType === 162 ? '-' : totalProjectPayAreaListReview(scope.row)}}
          </template>
        </el-table-column>
      </el-table-column>
<!--      <el-table-column prop="totalProjectNums" label="参与项目总数" align="center"  >-->
<!--        <el-table-column prop="totalProjectNums" label="普通" align="center" width="50" sortable>-->
<!--          <template slot-scope="scope">-->
<!--            {{ totalProject(scope.row) }}-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--        <el-table-column prop="totalProjectNums" label="复核" align="center" width="50" sortable>-->
<!--          <template slot-scope="scope">-->
<!--            {{ totalProjectNumsReview(scope.row) }}-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--      </el-table-column>-->
      <el-table-column  label="审核项目总数" align="center"  >
        <el-table-column prop="auditProjectNums" class-name="performance-column" label="普通" align="center" width="60">
          <template slot-scope="scope">
            {{totalAuditProjectListFormal(scope.row)}}
          </template>
        </el-table-column>
        <el-table-column prop="auditProjectNums" class-name="performance-column" label="复核" align="center" width="60">
          <template slot-scope="scope">
            {{totalAuditProjectListReview(scope.row)}}
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column prop="refuseProjectNums" label="拒领总数" align="center" width="60">
        <template slot-scope="scope">
          <el-button type="text" @click="getRefuse(scope.row)"> {{scope.row.refuseProjectNums}}</el-button>
        </template>
      </el-table-column>
<!--      <el-table-column prop="overdueDateTaskNum" label="逾期项目总数" align="center" width="50" sortable>-->
<!--        <template slot-scope="scope">-->
<!--          <el-button type="text" @click="getOverdueData(scope.row)"> {{scope.row.overdueDateTaskNum}}</el-button>-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column prop="userSubmitOpinionNum" label="提交总数(强条/应条/其他)" width='320' align="center">
        <el-table-column prop="userSubmitOpinionNum" class-name="performance-column" label="普通" align="center" width="160" >
          <template slot-scope="scope">
            {{ totalOpinionNumDataFormal(scope.row)}}
            ({{totalUserOpinionForceNumDataFormal(scope.row)}}/{{totalUserOpinionShouldNumDataFormal(scope.row)}}/{{totalUserOpinionOtherNumDataFormal(scope.row)}})
          </template>
        </el-table-column>
        <el-table-column prop="userSubmitOpinionNum" class-name="performance-column" label="复核" align="center" width="150">
          <template slot-scope="scope">
            {{totalOpinionNumDataReview(scope.row)}}
            ({{totalUserOpinionForceNumDataReview(scope.row)}}/{{totalUserOpinionShouldNumDataReview(scope.row)}}/{{totalUserOpinionOtherNumDataReview(scope.row)}})
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column prop="opinionNumsRate" label="初审报告审查意见占比 " align="center"  >
        <el-table-column prop="opinionNumsRate"  label="普通 " align="center" width="85" >
          <template slot-scope="scope">
            {{ totalUserOpinionSummaryNumDataFormal(scope.row) }}/{{ totalOpinionSummaryNumDataFormal(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column prop="opinionNumsRate"  label="复核 " align="center" width="85" >
          <template slot-scope="scope">
            {{ totalUserOpinionSummaryNumDataReview(scope.row) }}/{{ totalOpinionSummaryNumDataReview(scope.row) }}
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column prop="opinionNumsRate"  label="初审报告强条意见占比" align="center"  >
        <el-table-column prop="opinionNumsRate" class-name="performance-column" label="普通" align="center" width="85" >
          <template slot-scope="scope">
            {{ totalUserOpinionSummaryForceNumDataFormal(scope.row) }}/{{ totalOpinionSummaryForceNumDataFormal(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column prop="opinionNumsRate" class-name="performance-column" label="复核" align="center" width="85" >
          <template slot-scope="scope">
            {{ totalUserOpinionSummaryForceNumDataReview(scope.row) }}/{{ totalOpinionSummaryForceNumDataReview(scope.row) }}
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column prop="opinionNumsRate" label="初审报告应条意见占比" align="center"  >
        <el-table-column prop="opinionNumsRate" label="普通" align="center" width="85" >
          <template slot-scope="scope">
            {{ totalUserOpinionSummaryShouldNumDataFormal(scope.row) }}/{{ totalOpinionSummaryShouldNumDataFormal(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column prop="opinionNumsRate" label="复核" align="center" width="85" >
          <template slot-scope="scope">
            {{ totalUserOpinionSummaryShouldNumDataReview(scope.row) }}/{{ totalOpinionSummaryShouldNumDataReview(scope.row) }}
          </template>
        </el-table-column>
      </el-table-column>
<!--      <el-table-column prop="opinionNumsRate" label="初审报告深度规定占比" align="center"  >-->
<!--        <el-table-column prop="opinionNumsRate" label="普通" align="center" width="70" >-->
<!--          <template slot-scope="scope">-->
<!--            {{ totalOpinionSpecialNums(scope.row) }}/{{ totalOpinionSummarySpecialNums(scope.row) }}-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--        <el-table-column prop="opinionNumsRate" label="复核" align="center" width="70" >-->
<!--          <template slot-scope="scope" >-->
<!--            {{ totalOpinionSpecialNumsReview(scope.row) }}/{{ totalOpinionSummarySpecialNumsReview(scope.row) }}-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--      </el-table-column>-->
    </el-table>

    <el-dialog :title="`${projectTableData[0]?projectTableData[0].userName:''}-绩效明细`" :visible.sync="dialog.project.show" v-model="dialog.project.show"
               :close-on-click-modal="false" width='96%' >
      <div class="custom-div">
        绩效文字说明：1、绩效面积E=B*(C-D)；2、正确率C=(被采纳强条意见数/报告强条意见数)*0.6+(被采纳应条意见数/报告应条意见数)*0.3+(被采纳其他条意见数/报告其他条意见数)*0.1
        ；3、错误率D=(提出意见数-被采纳意见数)/报告意见数*0.1</div>
      <el-table id='personal' :data="tableDataList.projectList" border style="width: 100%" v-loading="listLoading" ref="multipleTable"
                :highlight-current-row='true' >
        <el-table-column type='index' label="序号" width='60' align="center"></el-table-column>
<!--        <el-table-column prop="areaName" label="所属区（县）" width='120' align="center"></el-table-column>-->
        <el-table-column prop="projectName" label="项目名称" width='280' align="center">  </el-table-column>
        <el-table-column prop="projectCoefficient" label="项目系数" width='60' align="center"></el-table-column>
        <el-table-column  label="报告意见" align="center">
          <el-table-column prop="totalOpinionSummaryNums" class-name="performance-column" label="总数" width='60' align="center">
            <template #default="scope">
              <el-button type="text" @click="tabledataDetail(scope.row,1)">{{scope.row.totalOpinionSummaryNums}}</el-button>
            </template>
          </el-table-column>
          <el-table-column prop="totalOpinionSummaryForceNums" class-name="performance-column" label="强条" width='60' align="center">
            <template #default="scope">
              <el-button type="text" @click="tabledataDetail(scope.row,2)">{{scope.row.totalOpinionSummaryForceNums}}</el-button>
            </template>
          </el-table-column>
          <el-table-column prop="totalOpinionSummaryShouldNums" class-name="performance-column" label="应条" width='60' align="center">
            <template #default="scope">
              <el-button type="text" @click="tabledataDetail(scope.row,3)">{{scope.row.totalOpinionSummaryShouldNums}}</el-button>
            </template>
          </el-table-column>
          <el-table-column prop="totalOpinionSummaryOtherNums" class-name="performance-column" label="其他" width='60' align="center">
            <template #default="scope">
              <el-button type="text" @click="tabledataDetail(scope.row,4)">{{scope.row.totalOpinionSummaryOtherNums}}</el-button>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="个人提交" align="center">
          <el-table-column prop="opinionNums" class-name="performance-column-other" label="总数" width='60' align="center"></el-table-column>
          <el-table-column prop="opinionForceNums" class-name="performance-column-other" label="强条" width='60' align="center"></el-table-column>
          <el-table-column prop="opinionShouldNums" class-name="performance-column-other" label="应条" width='60' align="center"></el-table-column>
          <el-table-column prop="opinionOtherNums" class-name="performance-column-other" label="其他" width='60' align="center"></el-table-column>
        </el-table-column>
        <el-table-column  label="个人被采纳" align="center">
          <el-table-column prop="opinionSummaryNums" class-name="performance-column" label="总数" width='60' align="center"> </el-table-column>
          <el-table-column prop="opinionSummaryForceNums" class-name="performance-column" label="强条" width='60' align="center"> </el-table-column>
          <el-table-column prop="opinionSummaryShouldNums" class-name="performance-column" label="应条" width='60' align="center"> </el-table-column>
          <el-table-column prop="opinionSummaryOtherNums" class-name="performance-column" label="其他" width='60' align="center"> </el-table-column>
        </el-table-column>
        <el-table-column prop="projectName" label="绩效计算" align="center">
          <el-table-column prop="projectArea" class-name="performance-column-other" label="项目面积(A)" align="center">
            <template slot-scope="scope">
              {{ tableDataList.expertType === 162 ? '-' :scope.row.projectArea }}
            </template>
          </el-table-column>
          <el-table-column prop="projectCoefficientArea" class-name="performance-column-other" label="审查面积(B)" align="center">
            <template slot-scope="scope">
              {{ tableDataList.expertType === 162 ? '-' : scope.row.projectCoefficientArea }}
            </template>
          </el-table-column>
          <el-table-column prop="projectCorrectRate" class-name="performance-column-other" label="正确率%(C)" align="center">
            <template slot-scope="scope">
              {{ tableDataList.expertType === 162 ? '-' : scope.row.projectCorrectRate }}
            </template>
          </el-table-column>
          <el-table-column prop="projectErrorRate" class-name="performance-column-other"  label="错误率%(D)" align="center">
            <template slot-scope="scope">
              {{ tableDataList.expertType === 162 ? '-' : scope.row.projectErrorRate }}
            </template>
          </el-table-column>
          <el-table-column prop="projectPayArea" class-name="performance-column-other" label="绩效面积(E)" align="center">
            <template slot-scope="scope">
              {{ tableDataList.expertType === 162 ? '-' : scope.row.projectPayArea }}
            </template>
          </el-table-column>
        </el-table-column>
        <!--        <el-table-column v-if="this.userOpinionDetailList" label="姓名(提交意见数)" width='320'-->
        <!--                         align="center">-->
        <!--          &lt;!&ndash; 使用 v-for 遍历数组中的每个元素 &ndash;&gt;-->
        <!--          <template v-for="item in this.userOpinionDetailList" >-->
        <!--            &lt;!&ndash; 在这里添加显示数组元素的内容 &ndash;&gt;-->
        <!--            &lt;!&ndash; 例如，假设数组中的每个元素都有一个名字属性 &ndash;&gt;-->
        <!--            <span>{{ item.userName+"("+item.userOpinionNums+")" }}</span><br>-->
        <!--          </template>-->
        <!--        </el-table-column>-->
<!--        <el-table-column prop="assignDate" label="分配时间" align="center"></el-table-column>-->
<!--        <el-table-column prop="completeDate" label="完成时间" align="center">-->
<!--          <template v-if="scope.row.completeTime" slot-scope="scope">-->
<!--            {{ scope.row.completeDate }}-->
<!--            {{ scope.row.overdueDate < scope.row.completeDate.substring(0, 10) ? ' （ 超 ）' : '' }}-->
<!--          </template>-->
<!--        </el-table-column>-->
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="dialog.project.show = false">关闭</el-button>
      </div>
    </el-dialog>
    <el-dialog title="拒领项目" :visible.sync="dialog.refuse.show" v-model="dialog.refuse.show"
               :close-on-click-modal="false" width='50%'>
      <el-table :data="refuseTableData" border style="width: 100%" v-loading="listLoading" ref="multipleTable"
                :highlight-current-row='true'>
        <el-table-column type='index' label="序号" width='60' align="center"></el-table-column>
        <el-table-column prop="projectName" label="项目名称" align="center"></el-table-column>
        <el-table-column prop="projectArea" label="图审面积" align="center"></el-table-column>
        <el-table-column prop="refuseReason" label="拒领原因" align="center"></el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="dialog.refuse.show = false">关闭</el-button>
      </div>
    </el-dialog>
    <el-dialog title="逾期任务" :visible.sync="dialog.overdueData.show" v-model="dialog.overdueData.show"
               :close-on-click-modal="false" width='70%'>
      <el-table :data="overdueDataTableData" border style="width: 100%" v-loading="listLoading" ref="multipleTable"
                :highlight-current-row='true'>
        <el-table-column type='index' label="序号" width='60' align="center"></el-table-column>
        <el-table-column prop="projectName" label="项目名称" align="center"></el-table-column>
        <el-table-column prop="createTime" label="分配时间" align="center"></el-table-column>
        <el-table-column prop="completeTime" label="完成时间" align="center"></el-table-column>
        <el-table-column prop="overdueDate" label="到期时间" align="center"></el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="getOverdueDataDialog ">关闭</el-button>
      </div>
    </el-dialog>
    <el-dialog title="逾期任务复核" :visible.sync="dialog1.overdueData.show" v-model="dialog1.overdueData.show"
               :close-on-click-modal="false" width='70%'>
      <el-table :data="overdueDataTableDataReview" border style="width: 100%" v-loading="listLoading" ref="multipleTable"
                :highlight-current-row='true'>
        <el-table-column type='index' label="序号" width='60' align="center"></el-table-column>
        <el-table-column prop="projectName" label="项目名称" align="center"></el-table-column>
        <el-table-column prop="createTime" label="分配时间" align="center"></el-table-column>
        <el-table-column prop="completeTime" label="完成时间" align="center"></el-table-column>
        <el-table-column prop="overdueDate" label="到期时间" align="center"></el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="dialog1.overdueData.show = false">关闭</el-button>
      </div>
    </el-dialog>
<!-- 明细详情   -->
    <el-dialog title="详情" :visible.sync="tabledataDetailDialog" v-model="tabledataDetailDialog"
               :close-on-click-modal="false" width='50%'>
      <el-table :data="detailDataList" border style="width: 100%" v-loading="listLoading" ref="multipleTable"
                :highlight-current-row='true'>
        <el-table-column type='index' label="序号" width='60' align="center"></el-table-column>
        <el-table-column prop="specificOpinions" label="项目名称" align="center"></el-table-column>
        <el-table-column prop="confirmProblem" label="图审面积" align="center"></el-table-column>
        <el-table-column prop="drStandardItemDescribe" label="拒领原因" align="center"></el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="tabledataDetailDialog = false">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import util from '../../../util/date'
import Toolbar from '../../components/Toolbar'
import { getButtonList } from '@/promissionRouter'
import {
  getUserRefuseProjectList,
  getUserProjectDetailList,
  getAreaDictionaryList,
  getStatisticsProjectDetailReportData ,
  getTaskDetailList, getUserStatisticList,
} from '@/api/api'
import Vue from 'vue'
import FileSaver from 'file-saver'
import * as XLSX from 'xlsx'

export default {
  components: { Toolbar },
  data () {
    return {
      tableDataList:[],
      overdueDataTableDataReview:[],
      overDue:[],
      // projectList:[],
      // projectType31Array:[],
      // projectType188Array:[],
      userOpinionDetailList: [],
      utils: '',
      buttonList: [],
      needData: '',
      filters: {
        name: '',
        assignedUserId: undefined,
        bizStartDate: undefined,
        bizEndDate: undefined,
        orderSort: undefined,
        provinceCode: undefined,
        expertType: undefined
      },
      tableData: [],
      projectTableData: [],
      projectTableData1: [],
      refuseTableData: [],
      refuseTableData1: [],
      datatoExport: [],
      datatoExport1: [],
      datatoExport2: [],
      datatoExport3: [],
      overdueDataTableData: [],
      listLoading: false,
      dialog: {
        refuse: {
          show: false
        },
        project: {
          show: false
        },
        overdueData: {
          show: false
        },
      },
      dialog1: {
        refuse: {
          show: false
        },
        project: {
          show: false
        },
        overdueData: {
          show: false
        },
      },
      pickerData: [],
      expertOptions: [{
        'id': 62,
        'namespace': 'CommonService.OrgPermission.ExpertIdentity',
        'key': 0,
        'value': '驻场图审工程师',
        'sort': 1,
        'remark': ''
      }, {
        'id': 63,
        'namespace': 'CommonService.OrgPermission.ExpertIdentity',
        'key': 1,
        'value': '在线图审专家',
        'sort': 2,
        'remark': ''
      }, {
        'id': 162,
        'namespace': 'CommonService.OrgPermission.ExpertIdentity',
        'key': 5,
        'value': '技术部专家',
        'sort': 6,
        'remark': ''
      }],//专家类型
      provinceSearchOptions: [],
      isExecuting: false,
      refuseId: '',
      projectId: '',
      tabledataDetailDialog:false,
      detailDataList:[],
      detailDataListReport:[],
    }
  },
  created () {
    this.utils = util
    let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : []
    this.buttonList = getButtonList(this.$route.path, routers)
    this.getDataList()
    this.init()
  },
  methods: {
    //点击姓名详情的某一行
    dataListDetail(val,item){
      this.detailDataList = []
      // console.log(val,'详情')
      let params = {
        projectId:val.projectId
      }
      getStatisticsProjectDetailReportData(params).then(res=>{
        if (res.data.success){
          // console.log(res.data.response,'detail22222222')
          this.detailDataListReport = res.data.response
          if (item === 1){
            this.detailDataList = this.detailDataListReport
          }
          if (item === 2){
            this.detailDataList = this.detailDataListReport.filter(item=>item.questionPropertieValue === '强')
          }
          if (item === 3){
            this.detailDataList = this.detailDataListReport.filter(item=>item.questionPropertieValue === '应')
          }
          if (item === 4){
            this.detailDataList = this.detailDataListReport.filter(item=>item.questionPropertieValue === '其他')
          }
        }
      })
    },
    //详情明细总数
    tabledataDetail(val,item){
      this.dataListDetail(val,item)
      this.tabledataDetailDialog = true

    },

    //项目总面积普通
    totalProjectAreaListFormal(row){
      return row.totalProjectAreaList.commonProjectNum
    },
    //项目总面积复核
    totalProjectAreaListReview (row) {
        return row.totalProjectAreaList.reviewProjectNum
    },
    //绩效面积普通
    totalProjectPayAreaListFormal(row){
      return row.totalProjectPayAreaList.commonProjectNum
    },
    //绩效面积复核
    totalProjectPayAreaListReview(row){
      return row.totalProjectPayAreaList.reviewProjectNum
    },
    //审核项目总数普通
    totalAuditProjectListFormal(row){
        return row.totalAuditProjectList.commonProjectNum
    },
    //审核项目总数复核
    totalAuditProjectListReview (row) {
        return row.totalAuditProjectList.reviewProjectNum
    },
    //逾期项目总数普通
    totalOverDueProjectListFormal(row){
        return row.totalOverDueProjectList.commonProjectNum
    },
    //逾期项目总数复核
    totalOverDueProjectListReview(row){
        return row.totalOverDueProjectList.reviewProjectNum
    },
    //提交总数（强条/应/其他）普通
    totalOpinionNumDataFormal(row){
      // if (row.totalOpinionNumData.dataType === '整数') {
        return row.totalOpinionNumData.commonProjectNum
      // } else {
      //   return row.totalOpinionNumData.commonProjectFloatNum
      // }
    },
    totalUserOpinionForceNumDataFormal(row){
      // if (row.totalUserOpinionForceNumData.dataType === '整数') {
        return row.totalUserOpinionForceNumData.commonProjectNum
      // } else {
      //   return row.totalUserOpinionForceNumData.commonProjectFloatNum
      // }
    },
    totalUserOpinionShouldNumDataFormal(row){
      // if (row.totalUserOpinionShouldNumData.dataType === '整数') {
        return row.totalUserOpinionShouldNumData.commonProjectNum
      // } else {
      //   return row.totalUserOpinionShouldNumData.commonProjectFloatNum
      // }
    },
    totalUserOpinionOtherNumDataFormal(row){
      // if (row.totalUserOpinionOtherNumData.dataType === '整数') {
        return row.totalUserOpinionOtherNumData.commonProjectNum
      // } else {
      //   return row.totalUserOpinionOtherNumData.commonProjectFloatNum
      // }
    },
    //提交总数（强条/应/其他）复核
    totalOpinionNumDataReview (row) {
      // if (row.totalOpinionNumData.dataType === '整数') {
        return row.totalOpinionNumData.reviewProjectNum
      // } else {
      //   return row.totalOpinionNumData.reviewProjectFloatNum
      // }
    },
    totalUserOpinionForceNumDataReview (row) {
      // if (row.totalUserOpinionForceNumData.dataType === '整数') {
        return row.totalUserOpinionForceNumData.reviewProjectNum
      // } else {
      //   return row.totalUserOpinionForceNumData.reviewProjectFloatNum
      // }
    },
    totalUserOpinionShouldNumDataReview (row) {
      // if (row.totalUserOpinionShouldNumData.dataType === '整数') {
        return row.totalUserOpinionShouldNumData.reviewProjectNum
      // } else {
      //   return row.totalUserOpinionShouldNumData.reviewProjectFloatNum
      // }
    },
    totalUserOpinionOtherNumDataReview (row) {
      // if (row.totalUserOpinionOtherNumData.dataType === '整数') {
        return row.totalUserOpinionOtherNumData.reviewProjectNum
      // } else {
      //   return row.totalUserOpinionOtherNumData.reviewProjectFloatNum
      // }
    },
    //初审报告审查意见占比普通
    totalUserOpinionSummaryNumDataFormal(row){
      // if (row.totalUserOpinionSummaryNumData.dataType==='整数'){
        return row.totalUserOpinionSummaryNumData.commonProjectNum
      // }else {
      //   return row.totalUserOpinionSummaryNumData.commonProjectFloatNum
      // }
    },
    totalOpinionSummaryNumDataFormal(row){
      // if (row.totalOpinionSummaryNumData.dataType === '整数') {
        return row.totalOpinionSummaryNumData.commonProjectNum
      // } else {
      //   return row.totalOpinionSummaryNumData.commonProjectFloatNum
      // }
    },
    //初审报告审查意见占比复核
    totalUserOpinionSummaryNumDataReview (row) {
      // if (row.totalUserOpinionSummaryNumData.dataType === '整数') {
        return row.totalUserOpinionSummaryNumData.reviewProjectNum
      // } else {
      //   return row.totalUserOpinionSummaryNumData.reviewProjectFloatNum
      // }
    },
    totalOpinionSummaryNumDataReview (row) {
      // if (row.totalOpinionSummaryNumData.dataType === '整数') {
        return row.totalOpinionSummaryNumData.reviewProjectNum
      // } else {
      //   return row.totalOpinionSummaryNumData.reviewProjectFloatNum
      // }
    },
    //初审报告强条意见占比普通
    totalUserOpinionSummaryForceNumDataFormal(row){
      // if (row.totalUserOpinionSummaryForceNumData.dataType === '整数') {
        return row.totalUserOpinionSummaryForceNumData.commonProjectNum
      // } else {
      //   return row.totalUserOpinionSummaryForceNumData.commonProjectFloatNum
      // }
    },
    totalOpinionSummaryForceNumDataFormal(row){
      // if (row.totalOpinionSummaryForceNumData.dataType === '整数') {
        return row.totalOpinionSummaryForceNumData.commonProjectNum
      // } else {
      //   return row.totalOpinionSummaryForceNumData.commonProjectFloatNum
      // }
    },
    //初审报告强条意见占比复核
    totalUserOpinionSummaryForceNumDataReview (row) {
      // if (row.totalUserOpinionSummaryForceNumData.dataType === '整数') {
        return row.totalUserOpinionSummaryForceNumData.reviewProjectNum
      // } else {
      //   return row.totalUserOpinionSummaryForceNumData.reviewProjectFloatNum
      // }
    },
    totalOpinionSummaryForceNumDataReview (row) {
      // if (row.totalOpinionSummaryForceNumData.dataType === '整数') {
        return row.totalOpinionSummaryForceNumData.reviewProjectNum
      // } else {
      //   return row.totalOpinionSummaryForceNumData.reviewProjectFloatNum
      // }
    },
    //初审报告应条意见占比普通
    totalUserOpinionSummaryShouldNumDataFormal (row) {
      // if (row.totalUserOpinionSummaryShouldNumData.dataType === '整数') {
        return row.totalUserOpinionSummaryShouldNumData.commonProjectNum
      // } else {
      //   return row.totalUserOpinionSummaryShouldNumData.commonProjectFloatNum
      // }
    },
    totalOpinionSummaryShouldNumDataFormal (row) {
      // if (row.totalUserOpinionSummaryShouldNumData.dataType === '整数') {
        return row.totalOpinionSummaryShouldNumData.commonProjectNum
      // } else {
      //   return row.totalUserOpinionSummaryShouldNumData.commonProjectFloatNum
      // }
    },
    //初审报告应条意见占比复核
    totalUserOpinionSummaryShouldNumDataReview (row) {
      // if (row.totalUserOpinionSummaryShouldNumData.dataType === '整数') {
        return row.totalUserOpinionSummaryShouldNumData.reviewProjectNum
      // } else {
      //   return row.totalUserOpinionSummaryShouldNumData.reviewProjectFloatNum
      // }
    },
    totalOpinionSummaryShouldNumDataReview (row) {
      // if (row.totalOpinionSummaryShouldNumData.dataType === '整数') {
        return row.totalOpinionSummaryShouldNumData.reviewProjectNum
      // } else {
      //   return row.totalOpinionSummaryShouldNumData.reviewProjectFloatNum
      // }
    },






    formatUserName (userName) {
      return userName.replace(/,/g, '<br><br>');
    },
    formatUserNames (userName) {
      return userName.replace(/,/g, '<br>');
    },
    callFunction (item) {
      this[item.func].apply(this, item)
    },

    init () {
      // 获取省份
      getAreaDictionaryList({ parentId: 1 }).then(res => {
        this.provinceSearchOptions = res.data.response.reverse()
      })
    },
    // 查询
    getListData () {
      this.getDataList()
    },
    // 列表查询接口
    getDataList () {
      let params = {
        assignedUserId: this.filters.assignedUserId,
        bizEndDate: this.filters.bizEndDate,
        bizStartDate: this.filters.bizStartDate,
        orderSort: this.filters.orderSort,
        provinceCode: this.filters.provinceCode,
        expertType: this.filters.expertType,
        userName:this.filters.name
      }
      getUserStatisticList(params).then(res => {
        const result = res.data;
        if (result.success) {
          this.tableData = result.response;

          this.mianTbaleList = res.data.response;
        }

      });

    },
    handleSelect (item) {
      this.filters.assignedUserId = item.id
    },
    querySearchAsync (queryString, cb) {

      let userList = this.tableData.map(user => user.userName);

    },
    createStateFilter (queryString) {
      return (state) => {
        return (state.userName.indexOf(queryString) === 0)
      }
    },
    // 封装执行数组循环的方法
    async dialogDemo () {
      let row = this.mianTbaleList

      for (const item of row) {
        // 执行循环中的操作，这里可以是异步操作
        // console.log(item,'item')
        await this.getRefuse1(item, item.id);
        await this.getProject1(item, item.id);
      }

    },
    async processItems () {
      const flattenedArray = this.refuseTableData1.reduce((accumulator, currentArray) => {
        return accumulator.concat(currentArray);
      }, []);
      const flattenedArray1 = this.projectTableData1.reduce((accumulator, currentArray) => {
        return accumulator.concat(currentArray);
      }, []);
      if (this.projectTableData1.length >= this.mianTbaleList.length && this.refuseTableData1.length >= this.mianTbaleList.length) {
        // this.datatoExport = [...flattenedArray1, ...flattenedArray]
        // console.log(this.projectTableData1, 'data1')
        for (const item of this.mianTbaleList) {
          const customIndex = item.id;
          // const targetProjectObjects = this.projectTableData1.forEach(item=>{item.filter(obj => customIndex===obj.customIndex)});
          const targetRefuseObjects = this.refuseTableData1.forEach(item => {item.filter(obj => customIndex === obj.customIndex)});

          // 遍历数组并将对象按ID分组
          // this.datatoExport.forEach((obj) => {
          //   const customIndex = item.id ;
          //   const idToObjectMap = [];
          //   if (idToObjectMap[customIndex]) {
          //     idToObjectMap[customIndex].push(obj);
          //   } else {
          //     idToObjectMap[customIndex] = [obj];
          //   }
          //   console.log(idToObjectMap,'cccc')
          // });

        }
        // console.log(this.datatoExport,'xxx')
        // 创建一个对象，用于存储具有相同ID的对象
        // const idToObjectMap = [];

        // 遍历数组并将对象按ID分组
        // this.datatoExport.forEach((obj) => {
        //   const customIndex = ;
        //   if (idToObjectMap[customIndex]) {
        //     idToObjectMap[customIndex].push(obj);
        //   } else {
        //     idToObjectMap[customIndex] = [obj];
        //   }
        // });
        // // 输出具有相同ID的小对象
        // for (const customIndex in idToObjectMap) {
        //   if (idToObjectMap[customIndex].length > 1) {
        //     console.log(`具有相同ID ${customIndex} 的小对象：`);
        //     idToObjectMap[customIndex].forEach((item) => {
        //       console.log(`   ${item.name}`);
        //     });
        //   }
        // }
        // console.log(idToObjectMap,'vvvv')
        // const flattenedArray = this.refuseTableData1.reduce((accumulator, currentArray) => {
        //   return accumulator.concat(currentArray);
        // }, []);
        // const flattenedArray1 = this.projectTableData1.reduce((accumulator, currentArray) => {
        //   return accumulator.concat(currentArray);
        // }, []);
        //   for (const item of this.mianTbaleList) {
        //     const arrayId1= item.id
        //     // console.log(this.projectTableData1,'[')
        //     console.log(this.projectTableData1,3333)
        //     const targetProjectObjects = this.projectTableData1.forEach(item => {item.filter(obj => arrayId1.includes(obj.customIndex))});
        //     const targetRefuseObjects = this.refuseTableData1.forEach(item=>{item.filter(obj => arrayId1.includes(obj.customIndex))});
        //
        //     // console.log(targetProjectObjects,'re')
        //     this.datatoExport = [...targetProjectObjects, ...targetRefuseObjects];
        //     if (this.datatoExport1.length < this.mianTbaleList.length) {
        //       this.datatoExport1.push(this.datatoExport);
        //     }
        //   }
      }
      // console.log(this.datatoExport1,'datatoExport1')
    },
    async exportListData () {
      let wb = XLSX.utils.book_new();
      // 导出主表格
      let mainTable = document.querySelector('#exportTab');
      let xlsxParam = { raw: true };
      let mainSheet = XLSX.utils.table_to_sheet(mainTable, xlsxParam);
      XLSX.utils.book_append_sheet(wb, mainSheet, '绩效考核');
      // 从多个子表格中导出数据
      for (let item of this.tableData) {
        this.tableDataList.projectList = [];
        // 显示当前子表格的对话框
        this.getProject(item)
        // 等待对话框完全渲染（您可能需要实现这一部分）
        await this.$nextTick();
        let oneTable = document.querySelector('#personal');
        let oneSheet = XLSX.utils.table_to_sheet(oneTable, xlsxParam);
        // 在Excel工作表中使用唯一名称来标识每个子表格
        XLSX.utils.book_append_sheet(wb, oneSheet, `子表-${item.userName}`);
        // 关闭当前子表格的对话框
        this.dialog.project.show = false;
      }
      // 将工作簿导出为Excel文件
      let wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'array' });
      try {
        FileSaver.saveAs(new Blob([wbout], { type: 'application/octet-stream' }), '绩效考核.xlsx');
      } catch (e) {
        if (typeof console !== 'undefined') {
          console.log(e, wbout);
        }
      }
      return wbout;
    },
    getRefuse (row) {
      getUserRefuseProjectList({ taskIds: row.refuseProjectTaskIds }).then(res => {
        if (res.data.success) {
          this.refuseTableData = res.data.response
          this.dialog.refuse.show = true
        }
      })
    },

    async getRefuse1 (row, index) {
      getUserRefuseProjectList({ taskIds: row.refuseProjectTaskIds }).then(res => {
        // if (res.data.success) {
        let array1 = res.data.response
        let customIndex = index; // 设置自定义下标的初始值
        if (this.refuseTableData1.length < this.mianTbaleList.length) {
          // 使用map函数为array1添加自定义下标值
          array1 = array1.map(item => {
            const customIndexValue = `${customIndex}`;
            return { ...item, customIndex: customIndexValue };
          });
          this.refuseTableData1.push(array1)
        }
      })

      // console.log(this.refuseTableData1)
    },
    async getProject1 (row, index) {
      getUserProjectDetailList({ taskIds: row.auditProjectTaskIds }).then(res => {
        let array2 = res.data.response
        let customIndex = index; // 设置自定义下标的初始值
        if (this.projectTableData1.length < this.mianTbaleList.length) {
          array2 = array2.map(item => {
            const customIndexValue = `${customIndex}`;
            return { ...item, customIndex: customIndexValue };
          });
          this.projectTableData1.push(array2)
        }

      })
    },
    getOverdueDataDialog(){
      this.dialog.overdueData.show=false
      this.overDue = undefined
      this.overdueDataTableData = undefined
    },
    getOverdueData (row) {
      // console.log(row,'row')
      this.overDue = undefined
      this.overdueDataTableData = undefined
      getTaskDetailList({ taskIds: row.overdueDateTaskIds }).then(res => {
        if (res.data.success) {
          this.overDue = res.data.response
          // console.log(this.overDue,'over')
          if (this.overDue){
            const aaa = this.overDue.filter(item => item.projectType !==188)
            console.log(aaa,'aaa')
            this.overdueDataTableData = aaa
            // console.log(this.overdueDataTableData,' this.overdueDataTableData ')
            this.dialog.overdueData.show = true
          }else {
            this.dialog.overdueData.show = true
          }
        }
      })
    },
    getOverDueReview(){
     if (this.overDue){
       const aaa = this.overDue.filter(item => item.projectType === 188)
       this.overdueDataTableDataReview = aaa.filter(item => item.completeDate >= item.overdueDate)
       this.dialog1.overdueData.show = true
     }else {
       this.dialog1.overdueData.show = true
     }

    },
    getProject (row) {
      this.tableDataList = row
      this.projectTableData = row.projectList
      // console.log(this.tableDataList, 111111)
      this.projectTableData.forEach(item => {
        item.userName = row.userName
        this.userOpinionDetailList = item.userOpinionDetailList
        item.userLevelName = row.userLevelName
      })
      this.dialog.project.show = true

    },
    // 日期选择
    changePicker (data) {
      if (data !== null) {
        this.filters.bizStartDate = data[0]
        this.filters.bizEndDate = data[1]
      } else {
        this.filters.bizStartDate = undefined
        this.filters.bizEndDate = undefined
      }
    },
    // 时间格式化
    formatCreateTime: function (row, column) {
      return !row.NoticeTime || row.NoticeTime == ''
          ? ''
          : util.formatDate.format(new Date(row.NoticeTime), 'yyyy-MM-dd hh:mm')
    },
  }
}
</script>

<style lang="stylus" scoped>
.custom-div {
  margin-bottom: 15px; /* Adds 15px margin outside the div */
  font-size : 16px
}

.grey-background {
  background-color: #f2f2f2; /* 使用你喜欢的颜色代码 */
}

.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>
